<script setup lang="ts">
import { ref, computed } from "vue";
import i18n from "@/i18n.js";
import { useStore } from "@/utils/useStore";
import { bvToastInstance } from "@/utils/useBvToast";
import {
  GrButton,
  GrModal,
  GrModalClose,
  GrModalContent,
  GrModalFooter,
  GrModalHeader,
} from "@/components/GrComponents";

const store = useStore();
const toast = bvToastInstance();
const isModalOpen = ref(false);
const loading = ref(false);
const canClose = ref(true);
const size = ref("md");

const props = defineProps({
  contract: {
    type: Object,
    default: {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const isDisabled = computed(() => props.disabled);

const renewContract = function () {
  store
    .dispatch("renewContract", props.contract.client_has_contract_id)
    .then((res) => {
      toast.toast(res.message, {
        title: "Renovação de contrato",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    });
};

const openModal = () => {
  if (!isDisabled.value) {
    isModalOpen.value = true;
  }
};

const tooltipMessageDisabled = () => {
  if (
    props.contract.contract_status === "ended" &&
    props.contract.chc_reference_id !== null
  ) {
    return `${i18n.t("views.seller.contracts_new.modal.text_015")}`;
  }
  if (props.contract.contract_status !== "ended") {
    return `${i18n.t("views.seller.contracts_new.modal.text_016")}`;
  }
};
</script>

<template>
  <GrModal
    v-model="isModalOpen"
    :close="canClose"
    renderTriggerTo="#TeleporRenderModalContractRenew"
    :disabled="isDisabled"
    :tooltipMessageDisabled="tooltipMessageDisabled()"
  >
    <template #open>
      <slot></slot>
    </template>

    <GrModalContent
      :size="size"
      customWidth="507"
      customHeight="478"
      :loading="loading"
      class="custom-modal-content"
    >
      <GrModalHeader />

      <div class="content-contract">
        <img
          src="@/assets/img/icons-contracts/renew-contract-modal.svg"
          alt="contracts"
        />
        <h2>{{ i18n.t("views.seller.contracts_new.modal.text_005") }}</h2>
        <p>
          {{ i18n.t("views.seller.contracts_new.modal.text_006") }}
        </p>
      </div>

      <GrModalFooter class="custom-modal-footer">
        <GrModalClose>
          <GrButton variant="gray" class="footer-button">
            {{ i18n.t("views.seller.contracts_new.modal.text_007") }}
          </GrButton>
        </GrModalClose>
        <GrButton
          @click="renewContract()"
          class="footer-button background-color"
        >
          <span class="custom-color">{{
            i18n.t("views.seller.contracts_new.modal.text_008")
          }}</span>
        </GrButton>
      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
.content-contract {
  display: grid;
  justify-items: center;
  text-align: center;
  margin-top: 18px;

  h2 {
    height: 30px;
    left: calc(50% - 319px / 2 + 1px);
    top: 201px;

    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;

    color: var(--old-800);
    margin-top: 48px;
  }

  p {
    width: 428px;
    height: 72px;
    left: calc(50% - 428px / 2 + 0.5px);
    top: 247px;

    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;

    color: var(--gray-200);
    margin-top: 16px;
  }
}

.footer-button {
  width: 200px;
  height: 55px;
}

.background-color {
  background-color: var(--primary-600);
}

.custom-color {
  color: var(--gray-white);
  font-weight: var(--weight-semibold);
}

.custom-modal-content {
  gap: 0px;
  align-items: center;
}

.custom-modal-footer {
  margin-top: 48px;
  gap: 22px;
  justify-content: center;
  position: relative;
}
</style>
